import React from "react";
import { Col, Row } from "react-bootstrap";
import teacherPart from "../assets/image/students-view.png";
const Students = () => {
  return (
    <div className="student-container" id="Student_Panel">
      <Row>
        <Col lg={6} className="my-auto">
          <div className="teacher-single-item">
            <h2>Gaming Console</h2>
            <p>
              This is your very own learning console, you take the charge of
              applied learning. The course once set for you, you take decisions,
              and see the real impact. Once you decide, you can undo, unlike in
              real world- a sense to form in your cerebra.
            </p>
            <div className="font-w-500">
              <p>
                Easy-to-use Panel | Automated results | Tutorial | Group
                Discussion | Decision Corner | Impact Zone
              </p>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="teacher-single-item  text-center">
            <img src={teacherPart} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Students;
