import React from "react";
import Marquee from "react-fast-marquee";
import "./feature.css";
import { featureContent } from "./index";

const Feature = () => {
  return (
    <div className="feature-main-area">
      <div className="container">
        <div className="feature-inner-area">
          <div className="feature-items-wrap">
            <div className="our-customer">
              <div>
                {/* <h6>Our Client</h6> */}
                <h2>Partners</h2>
              </div>
            </div>
            <Marquee>
              {featureContent.map((data) => (
                <>
                  {" "}
                  <div className="feature-single-item">
                    <img src={data.icon} alt="" />
                  </div>
                </>
              ))}
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
