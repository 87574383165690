import React, { useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useHistory } from "react-router-dom";
import "./callToAction.scss";
import popupImg from "./popup.png";
const axios = require("axios");
const CallToAction = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCloseModal = () => {
    // window.location.reload(false);
    setOpen(false);
  };
  const history = useHistory();
  const [email, setemail] = useState("");
  const API_URL =
    "https://api-ieltsx.salesx-staging.xyz/api/v1/maacroute/newsletter";
  const axiosemailcheck = async (data, options) => {
    try {
      setLoading(true);
      const response = await axios.post(API_URL, data);
      console.log(response.data);
      if (response.data.status) {
        setOpen(true);
        // history.replace("/");
        setLoading(false);
      } else {
        alert(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  function handlesubmit(e) {
    e.preventDefault();
    let obj = {
      email: email,
    };
    console.log(obj);
    axiosemailcheck(obj);
  }
  return (
    <div className="call-to-action-main-area">
      <Modal open={open} onClose={onCloseModal} center>
        <div className="call-to-action-popup-success">
          <img src={popupImg} alt="" />
          <h3>Thank You</h3>
          <p>Thanks for being in touch with us. We will connect you shortly.</p>
        </div>
      </Modal>
      <div className="container">
        <div className="call-to-action-area">
          <div className="call-to-action-left">
            <h2>
              Need our help?
              <br /> Contact with us.
            </h2>
          </div>
          <div className="call-to-action-right">
            <form onSubmit={handlesubmit}>
              <div className="call-to-action-input-wrap">
                <input
                  type="email"
                  placeholder="maac@gmail.com"
                  onChange={(e) => setemail(e.target.value)}
                  required
                />
                <button type="submit">
                  {loading ? "submitting... " : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
