import featue4 from "./img/1.png";
import featue5 from "./img/10.png";
import featue3 from "./img/11.png";
import featue6 from "./img/12.png";
import featue7 from "./img/14.png";
import featue11 from "./img/15.png";
import featue9 from "./img/2.png";
import featue8 from "./img/3.png";
import featue12 from "./img/4.png";
import featue13 from "./img/5.png";
import featue14 from "./img/6.png";
import featue15 from "./img/7.png";
import featue10 from "./img/9.png";

export const featureContent = [
  { icon: featue4 },
  { icon: featue3 },
  { icon: featue5 },
  { icon: featue6 },
  { icon: featue7 },
  { icon: featue8 },
  { icon: featue9 },
  { icon: featue10 },
  { icon: featue11 },
  { icon: featue12 },
  { icon: featue13 },
  { icon: featue14 },
  { icon: featue15 },
];
