import React from "react";
import { Col, Row } from "react-bootstrap";
import teacherPart from "../assets/image/teacher-view.png";
const TeacherPart = () => {
  return (
    <div className="teacher-main-area" id="Techer_Panel">
      <Row>
        <Col lg={6}>
          <div className="teacher-single-item text-center">
            <img src={teacherPart} alt="" />
          </div>
        </Col>
        <Col lg={6} className="my-auto">
          <div className="teacher-single-item">
            <h2>Handy for Teacher</h2>
            <p>
              Calibrated in such minute details that Teacher has the magic wand
              to alter the reality if needed. From a simple chapter to even a
              course can be built upon this gamified platform- along with a
              Teacher's corner to make it more tailor-made, handy, and embedded
              with close-to-reality mindset.
            </p>
            <div className="font-w-500">
              <p>
                Teacher Panel | Group Assignment | Individual Case | Gamifical
                Semester
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TeacherPart;
