import React from "react";
import footerLogo from "../../components/Home/assets/image/footer-logo.svg";
import insight from "../img/insight.svg";
import "./footer.css";
const MainFooter = () => {
  return (
    <div class="footer-main-area">
      <div class="container">
        <div class="footer-inner-wrap">
          <div class="row">
            <div class="col-lg-4 col-md-12 ">
              <div class="footer-single-item">
                <div class="footer-single-item-title">
                  <img src={footerLogo} alt="" />
                </div>
                <div class="footer-single-item-content">
                  <p>
                    Our SAAS based{" "}
                    <span>Simulated learning and gaming experience</span> is a
                    crucial step towards meaningful, impact driven digital
                    education targeted towards university students.
                  </p>
                  <p className="footer-mobile-hide">
                    © CalibraX {new Date().getFullYear()}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-6 display-none-responsive-device">
              <div class="footer-single-item footer-item-get-in-touch">
                <div class="footer-single-item-title">
                  <h5>Get in Touch</h5>
                </div>
                <div class="footer-single-item-content">
                  <span>
                    <span>
                      <i class="bx bx-map"></i>
                    </span>
                    <span>
                      House 15, Road 24, <br /> Gulshan - 2 Dhaka - 1212
                    </span>
                  </span>
                  <span>
                    <span>
                      <i class="bx bx-envelope"></i>
                    </span>
                    <span>solutionsmaac@gmail.com</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="footer-single-item">
                <div class="footer-single-item-title">
                  <h5>Want some insights?</h5>
                </div>
                <div class="footer-single-item-content">
                  <a
                    href="https://maacsolutions.com/insights"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={insight} alt="" />
                  </a>

                  {/* <label>Your Email</label>
                  <input type="email" placeholder="Enter Your Email" />
                  <button>Subscribe</button> */}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mobile-copy-right">
                <p>© CalibraX {new Date().getFullYear()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
