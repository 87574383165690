import React from "react";
import { Col, Row } from "react-bootstrap";
import teacherPart from "./img/Group 97719.svg";
const CalibraX = () => {
  return (
    <div className="calibrax-course-ware" id="course_ware">
      <Row>
        <Col lg={6}>
          <div className="teacher-single-item text-center  course-ware-logo">
            <img src={teacherPart} alt="" />
          </div>
        </Col>
        <Col lg={6} className="my-auto">
          <div className="teacher-single-item course-ware-area">
            <h2>
              <span className="course-logo-color-text">course</span>
              <span className="ware-logo-color-text">ware</span>
              <h6>Games Zone</h6>
            </h2>
            <p>
              You can’t know everything but you can always have a basic
              understandings on key concepts. Again a brain ware transformed
              into dynamic courses/elements- all at your clicks.
            </p>
            <div className="course-ware-area-know-more-btn">
              <a
                href="https://courseware.calibrax.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Know More</button>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CalibraX;
