import React from "react";
import { Container, Row } from "react-bootstrap";
import "./hero.scss";
import heroBg from "./HerorightBg.svg";
const Hero = () => {
  return (
    <div className="hero-main-wrap">
      <Container>
        <div className="hero-inner-wrap">
          <div className="hero-slider-items">
            <div className="hero-slider-single-item">
              <Row>
                <div className="col-lg-6 my-auto">
                  <div className="hero-slider-content-left">
                    <h2>
                      Discover <br /> Gamification
                    </h2>
                    <p>Learn | Leverage | Lead</p>
                    <div className="hero-area_btn-wrap">
                      <a
                        href="https://maacsolutions.com/contact"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hero-area navbar__link header-login-btn"
                      >
                        Contact Us
                      </a>
                      <a
                        href="https://maacsolutions.com/course-demo-request?coursename=calibraX"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hero-area navbar__link header-login-btn demo-request-btn"
                      >
                        Request for Demo
                      </a>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-6 my-auto">
                  <div className="hero-right-image">
                    <img src={heroBg} alt="" />
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
